const typeTemplate = "field is not a valid ${type}"

export const validateMessages = {
  default: "Validation error on field field",
  required: "field is required",
  enum: "field must be one of [${enum}]",
  whitespace: "field cannot be empty",
  date: {
    format: "field is invalid for format date",
    parse: "field could not be parsed as date",
    invalid: "field is invalid date"
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate
  },
  string: {
    len: "field must be exactly ${len} characters",
    min: "field must be at least ${min} characters",
    max: "field cannot be longer than ${max} characters",
    range: "field must be between ${min} and ${max} characters"
  },
  number: {
    len: "field must equal ${len}",
    min: "field cannot be less than ${min}",
    max: "field cannot be greater than ${max}",
    range: "field must be between ${min} and ${max}"
  },
  array: {
    len: "field must be exactly ${len} in length",
    min: "field cannot be less than ${min} in length",
    max: "field cannot be greater than ${max} in length",
    range: "field must be between ${min} and ${max} in length"
  },
  pattern: {
    mismatch: "field does not match pattern ${pattern}"
  }
}
