import { ConfigProvider } from 'antd'
import { validateMessages } from 'constraints/validateMessages'
import './assets/less/index.less'
import './assets/scss/index.scss'
import RootRoute from './router'

function App() {
  return (
    <ConfigProvider form={{ validateMessages: validateMessages }}>
      <RootRoute />
    </ConfigProvider>
  )
}

export default App
