import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(/background.svg);
  background-repeat: no-repeat;

  .footer {
    position: absolute;
    bottom: 1.25rem;
    font-size: 0.75rem;
    color: #fff;
  }
`
