import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ActionLogin, AuthState } from './types'
import {setToken, clearToken} from 'api/fetch'

const initialState = {
    token: null,
    isAuthenticated: false,
    session: null,
  } as AuthState

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
        state.token = null
        state.isAuthenticated = false
        state.session = null
        clearToken()
    },
    login(state, action: PayloadAction<ActionLogin>) {
        const { token, session } = action.payload
        state.token = token
        state.isAuthenticated = true
        state.session = session
        setToken(token)
    },
  },
})

export const authActions = authSlice.actions