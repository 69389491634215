import { LoadingOutlined } from '@ant-design/icons'
import { Space, Spin } from 'antd'
import { persistor, store } from 'globalStore'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import reportWebVitals from './reportWebVitals'

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin />)
Space.defaultProps = { size: 10 }
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root') as HTMLElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
