import { useMount } from 'ahooks'
import { useLocation, useNavigate } from 'react-router-dom'
const NavigateAuthCallback = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useMount(() => {
    navigate(
      {
        pathname: '/auth/callback',
        search: location.search
      },
      { replace: true }
    )
  })
  return null
}

export default NavigateAuthCallback
